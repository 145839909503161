import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/amuller/github-tkd/website-carbon/website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import LocalImage from './LocalImage';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageDescription mdxType="PageDescription">
      <p>{`We create business-driven data science solutions based on our understanding of your business needs, audience, and goals. Data science quality is all about ensuring that data and its predictions reflect the business objectives.`}</p>
    </PageDescription>
    <p>{`We select the `}<em parentName="p"><strong parentName="em">{`ML implementation`}</strong></em>{` approach that is cost-effective for your organization.`}<br />{`
We `}<strong parentName="p">{`implement`}</strong>{` the solution and `}<strong parentName="p">{`integrate`}</strong>{` it with your existing business processes.`}</p>
    <h2>{`Services`}</h2>
    <ul>
      <li parentName="ul">{`Exploratory Data Analysis (EDA)`}</li>
      <li parentName="ul">{`Data Leakage detection removal`}</li>
      <li parentName="ul">{`Explainable A.I. – driven development`}</li>
      <li parentName="ul">{`Model Building (training, testing, and validation)`}</li>
      <li parentName="ul">{`Data Procurement & Pipeline Construction`}</li>
      <li parentName="ul">{`Feature Engineering`}</li>
      <li parentName="ul">{`Production Deployment and Maintenance: maintain models, resolve concept drift (when necessary)`}</li>
    </ul>
    <h2>{`Technologies`}</h2>
    <Row className="technology-list" mdxType="Row">
      <Column colSm={2} colLg={3} colMd={3} className="tech-column" mdxType="Column">
        <LocalImage url={"https://www.python.org/"} imageSRC={'/images/tools/python_56.png'} altTxt={"Python"} mdxType="LocalImage" />
        <h3>
  Python 
  <span>Our main development language</span>
        </h3>
      </Column>
      <Column colSm={2} colLg={3} colMd={3} className="tech-column" mdxType="Column">
        <LocalImage url={"https://www.dask.org/"} imageSRC={'/images/tools/dask_56.png'} altTxt={"Dask"} mdxType="LocalImage" />
        <h3>
  Dask 
  <span>Library to scale natively, python packages and ecosystems</span>
        </h3>
      </Column>
      <Column colSm={2} colLg={3} colMd={3} className="tech-column" mdxType="Column">
        <LocalImage url={"https://numpy.org/"} imageSRC={'/images/tools/numpy_56.png'} altTxt={"Numpy"} mdxType="LocalImage" />
        <h3>
  NumPy 
  <span>Open-source library with functions for working with vectors and matrices</span>
        </h3>
      </Column>
      <Column colSm={2} colLg={3} colMd={3} className="tech-column" mdxType="Column">
        <LocalImage url={"https://numba.pydata.org/"} imageSRC={'/images/tools/numba_56.png'} altTxt={"Numba"} mdxType="LocalImage" />
        <h3>
  Numba 
  <span>Open-source JIT compiler that optimized solution performance</span>
        </h3>
      </Column>
      <Column colSm={2} colLg={3} colMd={3} className="tech-column" mdxType="Column">
        <LocalImage url={"https://pytorch.org/"} imageSRC={'/images/tools/pytorch_56.png'} altTxt={"PyTorch"} mdxType="LocalImage" />
        <h3>
  PyTorch 
  <span>Machine learning library, specialized in automatic differentiation, tensor computation and GPU acceleration</span>
        </h3>
      </Column>
      <Column colSm={2} colLg={3} colMd={3} className="tech-column" mdxType="Column">
        <LocalImage url={"https://scikit-learn.org/stable/"} imageSRC={'/images/tools/scikit-learn_56.png'} altTxt={"Scikit-learn"} mdxType="LocalImage" />
        <h3>
  Scikit-learn
  <span>Efficiently library for machine learning and statistical modeling with classification, regression, clustering, and dimensional reduction</span>
        </h3>
      </Column>
      <Column colSm={2} colLg={3} colMd={3} className="tech-column" mdxType="Column">
        <LocalImage url={"https://rapids.ai/"} imageSRC={'/images/tools/rapids_56.png'} altTxt={"Rapids"} mdxType="LocalImage" />
        <h3>
  Rapids
  <span>NVIDIA open-source suite to execute end-to-end data science and analytics pipelines on GPUs</span>
        </h3>
      </Column>
      <Column colSm={2} colLg={3} colMd={3} className="tech-column" mdxType="Column">
        <LocalImage url={"https://spark.apache.org/"} imageSRC={'/images/tools/spark_56.png'} altTxt={"Spark"} mdxType="LocalImage" />
        <h3>
  Apache Spark
  <span>Multi-language engine, distributed processing system used for big data workloads</span>
        </h3>
      </Column>
      <Column colSm={2} colLg={3} colMd={3} className="tech-column" mdxType="Column">
        <LocalImage url={"https://beam.apache.org/"} imageSRC={'/images/tools/beam_56.png'} altTxt={"Apache Beam"} mdxType="LocalImage" />
        <h3>
  Apache Beam
  <span>Open-source, unified model for defining both batch and streaming data-parallel processing pipelines</span>
        </h3>
      </Column>
      <Column colSm={2} colLg={3} colMd={3} className="tech-column" mdxType="Column">
        <LocalImage url={"https://flink.apache.org/"} imageSRC={'/images/tools/flink_56.png'} altTxt={"Apache Flink"} mdxType="LocalImage" />
        <h3>
  Apache Flink
  <span>Platform that provides a scalable, distributed, fault-tolerant, and stateful stream processing</span>
        </h3>
      </Column>
      <Column colSm={2} colLg={3} colMd={3} className="tech-column" mdxType="Column">
        <LocalImage url={"https://jupyter.org/"} imageSRC={'/images/tools/jupyter_56.png'} altTxt={"Jupyter"} mdxType="LocalImage" />
        <h3>
  Jupyter
  <span>Open-source web application that provides an interactive computational environment</span>
        </h3>
      </Column>
      <Column colSm={2} colLg={3} colMd={3} className="tech-column" mdxType="Column">
        <LocalImage url={"https://xgboost.readthedocs.io/en/stable/"} imageSRC={'/images/tools/XGBoost_56.png'} altTxt={"XGBoost"} mdxType="LocalImage" />
        <h3>
  XGBoost
  <span>A scalable, distributed gradient-boosted decision tree machine learning library</span>
        </h3>
      </Column>
      <Column colSm={2} colLg={3} colMd={3} className="tech-column" mdxType="Column">
        <LocalImage url={"https://lightgbm.readthedocs.io/en/v3.3.2/"} imageSRC={'/images/tools/lightGBM_56.png'} altTxt={"LightGBM"} mdxType="LocalImage" />
        <h3>
  LightGBM
  <span>A gradient boosting framework that uses tree-based learning algorithm and grows tree leaf-wise</span>
        </h3>
      </Column>
      <Column colSm={2} colLg={3} colMd={3} className="tech-column" mdxType="Column">
        <LocalImage url={"https://catboost.ai/"} imageSRC={'/images/tools/catBoost_56.png'} altTxt={"CatBoost"} mdxType="LocalImage" />
        <h3>
  CatBoost
  <span>Open-source library to use gradient boosting algorithms on decision trees</span>
        </h3>
      </Column>
      <Column colSm={2} colLg={3} colMd={3} className="tech-column" mdxType="Column">
        <LocalImage url={"https://optuna.org/"} imageSRC={'/images/tools/optuna_56.png'} altTxt={"Optuna"} mdxType="LocalImage" />
        <h3>
  Optuna
  <span>Open-source hyperparameter optimization framework to automate hyperparameter search</span>
        </h3>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      